<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class>Currency List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <div class="mr-3 mb-3 w-full sm:w-auto">
                <Multiselect
                  v-model="listType1"
                  style="width: 100px"
                  :options="['Active', 'All']"
                  @select="activeCurrency"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  openDirection="bottom"
                />
              </div>
              <!-- <div class="mr-2 mb-3">Filter:</div>
              <div class="mr-3 mb-3">
                <vs-dropdown>
                  <a class="flex items-center" href>
                    {{ SelectOption }}
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="allCurrency('All', null)">
                      All
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="activeCurrency('Active')">
                      Active
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div> -->

              <div class="mr-3 mb-3 w-full sm:w-auto">
                <vs-button
                  class="w-full sm:w-auto"
                  color="primary"
                  @click.prevent="activeAll"
                  type="filled"
                >
                  All Active
                </vs-button>
              </div>
              <div class="mr-3 sm:mr-0 mb-3 w-full sm:w-auto">
                <vs-button
                  class="col w-full sm:w-auto"
                  color="primary"
                  type="filled"
                  @click.prevent="inactiveAll"
                >
                  All Inactive
                </vs-button>
              </div>
              <vs-input
                class="mb-3 mr-3 ml-0 sm:ml-3 w-full sm:w-auto"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="currency"
          rowSelection="multiple"
          :suppressRowClickSelection="true"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        />
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
const VxTour = () => import("@/components/VxTour.vue");
import CurrencyService from "@/services/currencyService";
import SearchService from "@/services/searchService.js";

import CurrencyStatus from "./CurrencyStatus";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import Multiselect from "vue-multiselect";
window.isFirstColumn = function isFirstColumn(params) {
  console.log(params);
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};
export default {
  components: {
    AgGridVue,
    VxTour,
    Multiselect,
  },
  props: ["listType"],
  data() {
    return {
      listType1: "Active",
      totalPages: 0,
      archive: this.listType,
      currency: [],
      finalArray: [],
      SelectOption: "Select Option",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      options: ["Active", "Inactive"],
      columnDefs: [],
    };
  },
  beforeMount() {
    eventBus.$on("refreshTable", (e) => {
      this.getCurrencyList(this.currentPage);
    });
    this.columnDefs = [
      {
        headerName: "Select",
        filter: false,
        maxWidth: 150,
        headerCheckboxSelection: isFirstColumn,
        checkboxSelection: isFirstColumn,
      },
      {
        headerName: " Currency Name",
        field: "currency_name",
        filter: false,
        width: 450,
        suppressSizeToFit: true,
      },
      {
        headerName: "Currency Code",
        field: "currency_code",
        filter: false,
        width: 250,
      },
      {
        headerName: "Status",
        field: "currency_active",
        filter: false,
        minWidth: 200,
        maxWidth: 200,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: CurrencyStatus,
      },
    ];
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getCurrencyList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :");
    },
    onChangeStatus: function() {
      console.log("object");
    },
    updateSearchQuery(val) {
      SearchService.getAllSerchResult(val, "currency", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.currency = data.data;
          } else {
            this.currency = [];
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getSelectedRows() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      const selectedDataStringPresentation = selectedData
        .map((node) => node.make + " " + node.model)
        .join(", ");
    },
    getCurrencyList: function(currentPage) {
      if (this.SelectOption == "All") {
        this.allCurrency("All", currentPage);
      } else {
        this.SelectOption = "Active";
        let filter = {
          page: currentPage,
        };
        CurrencyService.getAllCurrency(filter)
          .then((response) => {
            const { data } = response;
            if (data.Status == true || data.Status == "true") {
              this.currency = data.data;
              this.totalPages = data.pagination.last_page;
              this.gridApi.sizeColumnsToFit();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    allCurrency: function(type, selectedPage) {
      this.$vs.loading();

      this.SelectOption = type;
      let filter = {
        page: selectedPage ? selectedPage : this.currentPage,
      };
      CurrencyService.getAllCurrencyList(filter)
        .then((response) => {
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.currency = data.data;
            this.totalPages = data.pagination.last_page;
            this.gridApi.sizeColumnsToFit();
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    activeCurrency: function(type) {
      this.SelectOption = type;
      this.getCurrencyList(this.currentPage);
    },
    inactiveCurrency: function(type) {
      this.SelectOption = type;
      let filter = {
        page: this.currentPage,
      };
      CurrencyService.getAllInactiveCurrencyList(filter)
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.currency = data.data;
            this.totalPages = data.pagination.last_page;
            this.gridApi.sizeColumnsToFit();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activeAll: function() {
      this.finalArray = [];
      this.gridApi.forEachNode((x) => {
        if (x.selected) {
          this.finalArray.push({
            currency_id: x.data.currency_id,
            currency_active: 1,
          });
        }
      });
      if (this.finalArray.length > 0) {
        this.$vs.loading();
        let payload = this.finalArray;
        CurrencyService.changeCurrencyStatus(payload)
          .then((response) => {
            const { data } = response;
            if (data.Status) {
              this.$vs.notify({
                title: "Updated!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.$vs.loading.close();
              this.getCurrencyList(this.currentPage);
            } else {
              this.$vs.notify({
                title: "Error",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: error.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          });
      } else {
        this.$vs.notify({
          title: "select at least one currency",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },

    inactiveAll: function() {
      this.finalArray = [];
      this.gridApi.forEachNode((x) => {
        if (x.selected) {
          this.finalArray.push({
            currency_id: x.data.currency_id,
            currency_active: 2,
          });
        }
      });
      if (this.finalArray.length > 0) {
        this.$vs.loading();
        let payload = this.finalArray;
        CurrencyService.changeCurrencyStatus(payload)
          .then((response) => {
            const { data } = response;
            if (data.Status) {
              this.$vs.notify({
                title: "Updated!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.$vs.loading.close();
              this.getCurrencyList(this.currentPage);
            } else {
              this.$vs.notify({
                title: "Error",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: error.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          });
      } else {
        this.$vs.notify({
          title: "select at least one currency",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },
  },
  mounted() {
    if (this.checkPermission(["currency", "GET"])) {
      this.getCurrencyList(this.currentPage);
    }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
