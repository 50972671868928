<template>
  <div>
    <b-form-checkbox
      @click.native="popupActive = true"
      value="1"
      unchecked-value="2"
      v-model="form.currency_active"
      switch
      class="table-status-icon"
    />

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS' + params.data.currency_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Currency Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status ?</h5>
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
              >
                Yes
              </vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">
                No
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CurrencyService from "@/services/currencyService.js";
export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        currency_active: "1",
      },
      copy_currency_active: null,
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_currency_active = this.form.currency_active;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalS" + this.params.data.currency_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function(e, value) {
      this.$vs.loading();
      let id = this.params.data.currency_id;
      let payload = {
        currency_id: this.form.currency_id,
        currency_active: this.form.currency_active,
      };
      CurrencyService.editCurrency(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            eventBus.$emit("refreshTable", data);
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.currency_active = this.copy_currency_active;
    },
  },
});
</script>
