<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/master">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <div class="vx-col w-full mb-base">
        <CurrencyForm v-if="checkPermission(['currency', 'POST'])" />
      </div>

      <div class="vx-col w-full mb-base">
        <CurrencyList v-if="checkPermission(['currency', 'GET'])" />
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyForm from "@/views/apps/masters/currency/CurrencyForm.vue";
import CurrencyList from "@/views/apps/masters/currency/CurrencyList.vue";

export default {
  components: {
    CurrencyForm,
    CurrencyList,
  },
  data() {
    return {};
  },
};
</script>
