<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Currency">
          <form>
            <div class="vx-row">
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-left sm:text-right align-self-center"
                  >
                    <h6 class="mb-0">Currency Name *</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required|alpha_spaces'"
                        name="Currency Name"
                        v-model="form.currency_name"
                        class="w-full"
                        rules="alpha"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Currency Name')"
                        >{{ errors.first("Currency Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-left sm:text-right align-self-center"
                  >
                    <h6 class="mb-0">Currency Code *</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required|alpha'"
                        name="Currency Code"
                        @keyup="
                          form.currency_code = form.currency_code.toUpperCase()
                        "
                        v-model="form.currency_code"
                        class="w-full"
                        rules="alpha"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Currency Code')"
                        >{{ errors.first("Currency Code") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-left sm:text-right">
                    <h6 class="mb-0">Status</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <b-form-checkbox
                      value="1"
                      unchecked-value="2"
                      v-model="form.currency_active"
                      name="check-button"
                      switch
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3 mb-3 w-full sm:w-auto"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class="mb-3 w-full sm:w-auto"
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index.js";
import CurrencyService from "@/services/currencyService";

export default {
  components: {},
  data() {
    return {
      submitStatus: true,
      form: {
        currency_name: "",
        currency_code: "",
        currency_active: 1,
      },
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.submitStatus = false;
          let payload = {
            ...this.form,
          };
          CurrencyService.addCurrency(payload)
            .then((response) => {
              this.submitStatus = false;
              const { data } = response;
              this.$vs.loading.close();
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                eventBus.$emit("refreshTable", data);

                this.clearForm();
              } else {
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.submitStatus = true;
            });
        }
      });
    },

    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.description = "";
      this.value = [];
      this.$validator.reset();
    },
  },
};
</script>
